(function () {

    angular
        .module('app.core')
        .service('AppStorage', storage)

    storage.$inject = ['calls', '$auth', '$rootScope']

    function storage(calls, $auth, $rootScope) {
        // properties
        var self = this


    }

})();