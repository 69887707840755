(function () {

    angular
            .module('app.home')
            .controller('KwsCtrl', home)

    home.$inject = ['utils', 'HomeService', '$stateParams', '$location', '$filter']

    function home(utils, service, stateParams, location, filter) {
        var self = this
        // public variables
        self.files = null
        self.domains = null
        self.selectedDomain = null
        self.selectedFile = null
        self.timestamp = null
        self.recordedInput = null
        self.player = null
        self.base64data = null
        
        // public methods
        self.requestTranscription = requestTranscription
        self.selectAudio = selectAudio
        self.selectDomain = selectDomain
        self.uploadFile = uploadFile
        self.record = record
        self.playRecording = playRecording
        self.pauseRecording = pauseRecording
        self.stopRecording = stopRecording
        self.selectPostProcessing = selectPostProcessing
        self.selectppCheckbox = selectppCheckbox
        // init
        init()

        //// init ////
        function init() {
            // get id from state
            var id = stateParams.id
            // get list of files
            service.getListOfFiles(function (error, files) {
                if (error) {
                    utils.hideLoader()
                    utils.showError('Could not fetch list of audio files')
                } else {
                    // get asr domains
                    service.getASRDomains(function (error, domains) {
                        utils.hideLoader()
                        if (error) {
                            utils.hideLoader()
                            utils.showError('Could not fetch ASR Domains')
                        } else {
                            self.files = files
                            self.domains = domains
                            self.selectedDomain = self.domains[0]
                            if (id)
                                initWithId(id)
                        }
                    })
                }

            })

        }

        //// upload new file ////
        function uploadFile(file) {
            utils.showLoader()
            console.log(file)
            service.uploadFile(file, function (error) {
                // handle response
                if (error) {
                    utils.hideLoader()
                    utils.showError()
                } else {
                    // re-init
                    init()
                }
            })
        }

        //// request transcription ////
        function requestTranscription() {
            // show loader
            utils.showLoader()
            // parse data
            var d = JSON.parse(self.selectedDomain)
            var f = JSON.parse(self.selectedFile)
            var pp = document.querySelector('.ppCheckbox').checked;
            console.log(pp)

            console.log("File: " + f);
            // make request
            if (f === null) {
                utils.hideLoader()
                utils.showError("Please select a file.")
            } else {
                var data = "{\"asr_domain_id\": "+d.asrDomainId+", \"post_processing\": "+pp+"}"
                console.log("Data: "+data)
                //var jsonData = JSON.parse(data)
                //console.log("jsonData: "+jsonData)

                service.requestTrnscriprion(f.audioId, data, function (error) {
                    // hide loader
                    utils.hideLoader()
                    // handle response

                    if (error) {
                        console.log(error);
                        utils.showError(error.data.message)
                    } else {
                        location.path('/jobs')
                    }
                })
                
            }
        }

        //// record audio file ////
        function record(file) {
            //self.timestamp = new Date();
            console.log(file)
            self.timestamp = filter('date')(new Date(), "yyyy-MM-dd-HH-mm-ss")
            console.log("Time: ")
            console.log(self.timestamp)
            console.log(self.timestamp + ".wav")
            file.name = self.timestamp + ".wav"
            console.log(file)
            uploadFile(file)
        }

        //// play recording ////
        function playRecording() {
            self.player.play()
            console.log("Playing recording...")
            console.log(self.player.paused)
            console.log(self.player.ended)
        }

        //// pause recording ////
        function pauseRecording() {
            self.player.pause()
            console.log("Pausing recording...")
            console.log(self.player.paused)
            console.log(self.player.ended)
        }

        //// stop recording ////
        function stopRecording() {
            self.player.pause()
            self.player.currentTime = 0
        }

        //// Helper -> init with id  ////
        function initWithId(id) {
            // TODO: Investigate why this does not work
            for (var i in self.files) {
                if (self.files[i]['audioId'] == id) {
                    self.selectedFile = self.files[i]
                    break
                }
            }
            console.log(self.selectedFile)

        }
        
        //// Helper -> select ppCheckbox ////
        function selectppCheckbox() {
            var checkBox = document.querySelector('.ppCheckbox').checked;
            console.log('ppCheckbox: ', checkBox)
        }

        //// Helper -> select domain ////
        function selectDomain() {
            console.log('select domain', self.selectedDomain)
        }
        
        //// Helper -> select postProcessing ////
        function selectPostProcessing() {
            console.log('select post processing', self.selectedDomain)
        }

        //// Helper -> select audio ////
        function selectAudio() {
            console.log('select audio', self.selectedFile)
            var f = JSON.parse(self.selectedFile)
            console.log(f.audioId)
            service.downloadFile(f.audioId, function (error, result) {
                console.log(error)
                if (error)
                    utils.showError()
                else {
                    console.log(result)
                    console.log("Checks:")
                    console.log(typeof result)
                    var data = new Blob([result.data]);
                    var reader = new FileReader();
                    reader.readAsDataURL(data);
                    reader.onloadend = function () {
                        console.log("Base64: ")
                        self.base64data = reader.result;
                        console.log(self.base64data);
                        //self.base64data = self.base64data.split(',')[1];
                        //var txt = "data:audio/wav;base64," + self.base64data
                        //console.log("Txt: ")
                        //console.log(txt)
                        self.player = new Audio(self.base64data)
                        console.log(self.player.paused)
                        console.log(self.player.ended)
                    }
                }
            })
        }


    }

})();




