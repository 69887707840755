(function () {

    angular
        .module('app.auth')
        .controller('SignupCtrl', signup)

    signup.$inject = ['$auth', '$location', '$rootScope', 'utils', 'AuthFactory', 'md5']
    function signup($auth, $location, $rootScope, utils, factory, md5) {
        var self = this
        self.signup = signup
        self.user = {}
        // init
        init()

        function init() {
            utils.hideLoader()
        }

        /// signup ////
        function signup() {
            // show loader
            utils.showLoader()
	    // encrypt password
            // create deep copy for user object so the password is not replaced in front-end
            var _user = angular.copy(self.user)
            _user.password = md5.createHash(self.user.password)
            // make request
            factory.signup(_user, function (error, token) {
                // hide loader
                utils.hideLoader()
                // handle response
                if (error)
                    utils.showError(error.data.message)
                else {
                    $location.path('/audio-files')
                }

            })
        }

    }
})();
