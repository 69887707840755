(function () {
    angular
        .module('app.core')
        .controller('AppCtrl', app)

    app.$inject = ["$scope", "$timeout", "$auth", "$rootScope"]
    function app($scope, $timeout, $auth, $rootScope) {
        var self = this
        self.isAuthenticated = $auth.isAuthenticated()

        $rootScope.$on('loggedin', function () {
            self.isAuthenticated = $auth.isAuthenticated()
        })

        $rootScope.$on('loggedout', function () {
            self.isAuthenticated = $auth.isAuthenticated()
        })

    }
})();