
// navbar service
(function () {
    angular
        .module('app.core')
        .service('NavService', service)

    service.$inject = ['$rootScope']
    function service($rootScope) {
        var self = this

        var buttons = [
            {
                "title": 'Home',
                "href": "home"
            }
        ]
        this.getButtons = function () {
            return buttons
        }

    }

})();