(function () {

    angular
        .module('app.home')
        .controller('AudioFilesCtrl', home)

    home.$inject = ['utils', 'HomeService', 'FileSaver', 'Blob', '$location']

    function home(utils, service, FileSaver, Blob, location) {
        var self = this
        // public variables
        self.files = null
        self.jobs = null
        // public methods
        self.uploadFile = uploadFile
        self.deleteFile = deleteFile
        self.requestTranscription = requestTranscription
	self.downloadFile = downloadFile

        // init
        init()

        //// init ////
        function init() {
            // get list of files
            service.getListOfFiles(function (error, files) {
                if (error) {
                    utils.hideLoader()
                    utils.showError('Could not fetch list of audio files')
                } else {
                    // get jobs
                    service.getListOfJobs(function (error, jobs) {
                        utils.hideLoader()
                        if (error)
                            utils.showError()
                        else {
                            self.files = files
                            self.jobs = jobs

                            // add file status from jobs
                            completeFileStatus()

                            console.log(files, jobs)
                        }


                    })

                }
            })

        }

        //// upload new file ////
        function uploadFile(file) {
            utils.showLoader()
            service.uploadFile(file, function (error) {
                // handle response
                if (error) {
                    utils.hideLoader()
                    utils.showError()
                } else {
                    // re-init
                    init()
                }
            })
        }

        //// delete file ////
        function deleteFile(file) {
            // show loader
            utils.showLoader()

            service.deleteFile(file.audioId, function (error) {
                // hide loader
                utils.hideLoader()
                // handle response
                if (error)
                    utils.showError(error.data.message)
                else {
                    utils.showSuccess()
                    var index = self.files.indexOf(file)
                    self.files.splice(index, 1)
                }
            })


        }

        //// request spotting ////
        function requestTranscription(id) {
            url = '/transcriber/' + id
            location.path(url)
        }

        //// match jobs and files for status ////
        function completeFileStatus() {
            self.files.forEach(function (file) {
                // convert length of file to milieconds
                file.length = file.length * 1000
                // add status
                var jb = self.jobs.filter(function (job) {
                    return job.audioId == file.audioId
                })

                if (jb && jb.length > 0)
                    file.status = jb[0].status

            }, this)
        }

	//// download ////
        function downloadFile(audioid, fileName) {
            console.log(audioid)
            service.downloadFile(audioid, function (error, result) {
                console.log(error)
                if (error)
                    utils.showError(error.data.message)
                else {
                    console.log(result)
                    console.log("Checks:")
                    console.log(typeof result)

                    var data = new Blob([result.data], { type: 'application/octet-stream' });

                    FileSaver.saveAs(data, fileName);
                }
            })
        }



    }

})();

