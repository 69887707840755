(function () {

    angular
        .module('app.home')
        .controller('HomeCtrl', home)

    home.$inject = ['utils', 'HomeService', '$location']

    function home(utils, service, location) {
        var self = this
        // self.jobs = undefined
        // self.files = undefined
        // public methods
        // self.uploadFile = uploadFile
        // self.process = process
        // self.downloadFile = downloadFile

        // // init
        init()

        function init() {
            location.path('/kws/')
        }

        // //// init ////
        // function init() {

        //     // get list of jobs
        //     service.getListOfJobs(function (error, jobs) {
        //         // handle response
        //         if (error) {
        //             utils.hideLoader()
        //             utils.showError()
        //         } else {
        //             // get list of files
        //             service.getListOfFiles(function (error, files) {
        //                 // hide loader
        //                 utils.hideLoader()
        //                 // handle response
        //                 if (error)
        //                     utils.showError()
        //                 else {
        //                     var b = jobs.filter(function (j) {
        //                         return j.status != 'completed'
        //                     })

        //                     self.jobs = b
        //                     self.files = files
        //                     console.log(self.files, self.jobs)
        //                 }

        //             })
        //         }

        //     })
        // }

        // //// upload file ////
        // function uploadFile(file) {
        //     utils.showLoader()
        //     service.uploadFile(file, function (error) {
        //         // hide loader
        //         utils.hideLoader()
        //         // handle response
        //         if (error)
        //             utils.showError()
        //         else {
        //             // request files
        //             service.getListOfFiles(function (error, files) {
        //                 utils.showSuccess()
        //                 self.files = files
        //             })

        //         }
        //     })
        // }

        // //// push to que ////
        // function process(id) {
        //     // show loader
        //     utils.showLoader()
        //     service.restoreDiacritics(id, function (error, data) {
        //         // hide loader
        //         utils.hideLoader()
        //         // handle response
        //         if (error)
        //             utils.showError()
        //         else {
        //             // re-init
        //             init()
        //         }
        //     })
        // }

        // //// download ////
        // function downloadFile(id) {
        //     console.log(id)
        //     service.downloadFile(id, function (error, result) {
        //         console.log(error)
        //         if (error)
        //             utils.showError()
        //         else {
        //             console.log(result)
        //         }
        //     })
        // }
    }

})();
