(function () {

    angular
        .module('app.core')
        .service('urls', urls)

    function urls() {
        var self = this
        this.defaultUrl = 'https://transcriptions.speed.pub.ro'

        this.loginURL = this.defaultUrl + '/GatewayAPI/users/login'
        this.signupURL = this.defaultUrl + '/GatewayAPI/users/register'

        this.getAudioListURL = this.defaultUrl + '/GatewayAPI/audio' // get
        this.getAudioJobsURL = this.defaultUrl + '/GatewayAPI/transcriptions/jobs' // get

        this.deleteAudioURL = this.defaultUrl + '/GatewayAPI/audio/' // delete + id

        this.getAsrURL = this.defaultUrl + '/GatewayAPI/transcriptions/domains' // get

        this.postTranscriptionRequestURL = this.defaultUrl + '/GatewayAPI/transcriptions/' // post + audio_id
        this.getTranscriptionURL = this.defaultUrl + '/GatewayAPI/transcriptions/' // get + job_id

        this.getFileURL = this.defaultUrl + '/GatewayAPI/audio/' // get + audio id
        this.uploadAudioFileURL = this.defaultUrl + '/GatewayAPI/audio' // post

        this.getUserInfo = this.defaultUrl + '/GatewayAPI/users/info' // get
        this.updateUserInfo = this.defaultUrl + '/GatewayAPI/users/info' // put

    }
})();
