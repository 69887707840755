
(function () {

    angular
        .module('Speech')
        .config(config)

    function config($stateProvider, $urlRouterProvider, $locationProvider, $qProvider) {

        $urlRouterProvider.otherwise('/')

        $qProvider.errorOnUnhandledRejections(false)
    }

})();