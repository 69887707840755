(function () {

    angular
        .module('app.core')
        .service('AuthService', service)

    service.$inject = ['$q', '$auth', '$location']
    function service($q, $auth, $location) {
        // jump over if user is logged in
        this.skipIfLoggedIn = function () {
            var deferred = $q.defer()
            if ($auth.isAuthenticated()) {
                deferred.reject()
            } else {
                deferred.resolve()
            }
            return deferred.promise
        }
        // restrict access method if user is not logged in
        this.loginRequired = function () {
            var deferred = $q.defer()
            if ($auth.isAuthenticated()) {
                deferred.resolve()
            } else {
                $location.path('/login')
            }
            return deferred.promise
        }
    }
})();