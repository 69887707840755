(function () {
    angular
        .module('app.home', ['app.core'])
        .config(config)

    function config($stateProvider) {
        $stateProvider
            .state('home', {
                url: '/',
                templateUrl: 'app/main/home/home.html',
                controller: 'HomeCtrl',
                controllerAs: 'vm',
                resolve: {
                    loginRequired:
                    function (AuthService) {
                        return AuthService.loginRequired()
                    }
                }
            })
            .state('audio-list', {
                url: '/audio-files',
                templateUrl: 'app/main/home/audio_files.html',
                controller: 'AudioFilesCtrl',
                controllerAs: 'vm',
                resolve: {
                    loginRequired:
                    function (AuthService) {
                        return AuthService.loginRequired()
                    }
                }
            })
            .state('jobs-list', {
                url: '/jobs',
                templateUrl: 'app/main/home/jobs.html',
                controller: 'JobsCtrl',
                controllerAs: 'vm',
                resolve: {
                    loginRequired:
                    function (AuthService) {
                        return AuthService.loginRequired()
                    }
                }
            })
            .state('transcriber', {
                url: '/transcriber/:id',
                templateUrl: 'app/main/home/kwd.html',
                controller: 'KwsCtrl',
                controllerAs: 'vm',
                resolve: {
                    loginRequired:
                    function (AuthService) {
                        return AuthService.loginRequired()
                    }
                }
            })
            .state('view-transcription', {
                url: '/view-transcription/:id/:audio/:fileName/:asrDomainName',
                templateUrl: 'app/main/home/view_kwd.html',
                controller: 'ViewKwdCtrl',
                controllerAs: 'vm',
                resolve: {
                    loginRequired:
                    function (AuthService) {
                        return AuthService.loginRequired()
                    }
                }
            })




    }

})();

