
// navbar controller
(function () {

    angular
        .module('app.core')
        .controller('NavCtrl', ['NavService', '$auth', '$rootScope', '$location', navbar])

    function navbar(buttons, $auth, $rootScope, $location) {
        var self = this
        self.isAuthenticated = isAuthenticated
        self.isAdmin = isAdmin
        self.logout = logout

        function isAuthenticated() {
            return $auth.isAuthenticated()
        }

        function isAdmin() {
            return $auth.isAdmin()
        }

        function logout() {
            $auth.removeToken()
            $location.path('/login')
        }

    }

})();