(function () {
    angular
        .module('app.user', ['app.core'])
        .config(config)

    function config($stateProvider) {
        $stateProvider
            .state('user', {
                url: '/user',
                templateUrl: 'app/main/user/user.html',
                controller: 'UserCtrl',
                controllerAs: 'vm',
                resolve: {
                    loginRequired:
                    function (AuthService) {
                        return AuthService.loginRequired()
                    }
                }
            })
    }

})();