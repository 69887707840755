(function () {

    angular
            .module('app.home')
            .controller('ViewKwdCtrl', home)

    home.$inject = ['utils', 'HomeService', 'FileSaver', 'Blob', '$stateParams', '$location']

    function home(utils, service, FileSaver, Blob, stateParams, location) {
        var self = this
        // public variables
        self.jobs = null
        self.fileName = null
        self.asrDomainName = null
        self.transcription = null;
        self.wavesurfer = null
        self.base64data = null
        self.syncData = {}
        self.speakers = []
        self.subtitles = document.getElementById("subtitles");
        self.currentIndex = 0;

        // public methods
        self.playPauseRecording = playPauseRecording
        self.createSubtitle = createSubtitle
        self.resetTextHighlight = resetTextHighlight
        self.setTextOptions = setTextOptions
        self.randomColor = randomColor
        self.downloadJSON = downloadJSON

        // init
        init()

        //// init ////
        function init() {
            // get id from state
            var id = stateParams.id
            var audioId = stateParams.audio
            self.fileName = stateParams.fileName
            self.asrDomainName = stateParams.asrDomainName
            self.wavesurfer = WaveSurfer.create({
                container: '#waveform',
                plugins: [
                    WaveSurfer.regions.create()
                ]
            });

            self.wavesurfer.on('audioprocess', function () {
                if (self.wavesurfer.isPlaying()) {
                    var currentTime = self.wavesurfer.getCurrentTime();
                    document.getElementById('time-current').innerText = currentTime.toFixed(2);

                    for (var i = self.currentIndex; i < self.currentIndex + 5; i++) {
                        //check if element exists (needed at end of array)
                        if (typeof self.syncData[i] !== 'undefined') {
                            if (self.syncData[i].hasOwnProperty('start')) {
                                if (self.wavesurfer.getCurrentTime() >= self.syncData[i].start && self.wavesurfer.getCurrentTime() <= self.syncData[i].start + self.syncData[i].length) {
                                    self.subtitles.children[i].style.background = 'yellow';
                                    self.currentIndex = i;
                                } else {
                                    self.subtitles.children[i].style.background = 'white';

                                }
                                if (self.currentIndex !== 0) {
                                    self.subtitles.children[self.currentIndex - 1].style.background = 'white';
                                }
                            }
                        }
                    }
                    /*
                     for (var i = 0; i < Object.keys(self.syncData).length; i++) {
                     if (self.syncData[i].hasOwnProperty('start')) {
                     if (self.wavesurfer.getCurrentTime() >= self.syncData[i].start && self.wavesurfer.getCurrentTime() <= self.syncData[i].start + self.syncData[i].length) {
                     self.subtitles.children[i].style.background = 'yellow';
                     } else {
                     self.subtitles.children[i].style.background = 'white';
                     }
                     }
                     }
                     */
                }
            });

            // minor problems on seeking; if a no voice zone is clicked, index is not updated! Suggestion: select word between starts of word, not start and end
            self.wavesurfer.on('seek', function () {
                var currentTime = self.wavesurfer.getCurrentTime();
                document.getElementById('time-current').innerText = currentTime.toFixed(2);
                resetTextHighlight();
                for (var i = 0; i < Object.keys(self.syncData).length; i++) {
                    if (self.syncData[i].hasOwnProperty('start')) {

                        var j = i + 1;
                        while (!self.syncData[j].hasOwnProperty('start')) {
                            j = j + 1;
                        }

                        if (self.wavesurfer.getCurrentTime() >= self.syncData[i].start && self.wavesurfer.getCurrentTime() <= self.syncData[j].start) {
                            self.subtitles.children[i].style.background = 'yellow';
                            self.currentIndex = i;
                            break;
                        }
                    }
                }
            });
            self.wavesurfer.on('pause', function () {
                document.getElementById('playPause').innerText = "play_arrow";
            });
            self.wavesurfer.on('play', function () {
                document.getElementById('playPause').innerText = "pause";
            });
            self.wavesurfer.on('finish', function () {
                self.currentIndex = 0;
            });

            if (id && audioId) {
                // get list of transcription
                service.getTranscription(id, function (error, transcription) {
                    if (error) {
                        utils.showError()
                    } else {
                        console.log("Transcription: ")
                        console.log(transcription)
                        self.transcription = JSON.stringify(transcription);
                        var json1 = JSON.parse(JSON.stringify(transcription));
                        console.log(json1)

                        //transcription parsing
                        if (transcription.hasOwnProperty('post-processed-transcription')) {
                            var k = 0;
                            for (var i = 0; i < transcription["post-processed-transcription"].segments.length; i++) {
                                //self.speakers[i] = JSON.parse('{"speaker_name": "' + transcription["post-processed-transcription"].segments[i].speaker_name + '", "speaker_gender": "' + transcription["post-processed-transcription"].segments[i].speaker_gender + '"}');
                                self.speakers[i] = transcription["post-processed-transcription"].segments[i].speaker_name;
                                for (var j = 0; j < transcription["post-processed-transcription"].segments[i].result.hypotheses["word-alignment"].length; j++) {
                                    if (j === 0) {
                                        self.syncData[k] = JSON.parse('{"speaker_name": "' + transcription["post-processed-transcription"].segments[i].speaker_name + '", "speaker_gender": "' + transcription["post-processed-transcription"].segments[i].speaker_gender + '"}');
                                        self.syncData[k + 1] = transcription["post-processed-transcription"].segments[i].result.hypotheses["word-alignment"][j];
                                        k = k + 2;
                                    } else if (j === transcription["post-processed-transcription"].segments[i].result.hypotheses["word-alignment"].length - 1) {
                                        self.syncData[k] = transcription["post-processed-transcription"].segments[i].result.hypotheses["word-alignment"][j];
                                        self.syncData[k + 1] = "Break";
                                        k = k + 2;
                                    } else {
                                        self.syncData[k] = transcription["post-processed-transcription"].segments[i].result.hypotheses["word-alignment"][j];
                                        k = k + 1;
                                    }
                                }
                                /*
                                 if (i === 0) {
                                 //this will be a reference and not  copy; when we use push.apply, the word alignment[0] will have all the words
                                 self.syncData = transcription["post-processed-transcription"].segments[i].result.hypotheses["word-alignment"];
                                 console.log(self.syncData)
                                 } else {
                                 //extend syncData array with word alignment array from next segment
                                 self.syncData.push.apply(self.syncData, transcription["post-processed-transcription"].segments[i].result.hypotheses["word-alignment"]);
                                 console.log(self.syncData)
                                 }
                                 */
                            }
                        } else {
                            var k = 0;
                            for (var i = 0; i < transcription.transcript.segments.length; i++) {
                                //self.speakers[i] = JSON.parse('{"speaker_name": "' + transcription.transcript.segments[i].speaker_name + '", "speaker_gender": "' + transcription.transcript.segments[i].speaker_gender + '"}');
                                self.speakers[i] = transcription.transcript.segments[i].speaker_name;
                                for (var j = 0; j < transcription.transcript.segments[i].result.hypotheses[0]["word-alignment"].length; j++) {
                                    if (j === 0) {
                                        self.syncData[k] = JSON.parse('{"speaker_name": "' + transcription.transcript.segments[i].speaker_name + '", "speaker_gender": "' + transcription.transcript.segments[i].speaker_gender + '"}');
                                        self.syncData[k + 1] = transcription.transcript.segments[i].result.hypotheses[0]["word-alignment"][j];
                                        k = k + 2;
                                    } else if (j === transcription.transcript.segments[i].result.hypotheses[0]["word-alignment"].length - 1) {
                                        self.syncData[k] = transcription.transcript.segments[i].result.hypotheses[0]["word-alignment"][j];
                                        self.syncData[k + 1] = "Break";
                                        k = k + 2;
                                    } else {
                                        self.syncData[k] = transcription.transcript.segments[i].result.hypotheses[0]["word-alignment"][j];
                                        k = k + 1;
                                    }
                                }

                                /*
                                 if (i === 0) {
                                 self.syncData = transcription.transcript.segments[i].result.hypotheses[0]["word-alignment"];
                                 } else {
                                 //extend syncData array with word alignment array from next segment
                                 self.syncData.push.apply(self.syncData, transcription.transcript.segments[i].result.hypotheses[0]["word-alignment"]);
                                 
                                 }
                                 */
                            }
                        }
                        //end transcription parsing
                        
                        //add span elements to our subtitles
                        createSubtitle();
                        //make text clickable
                        setTextOptions();

                        service.downloadFile(audioId, function (error, result) {
                            if (error) {
                                utils.showError();
                            } else {
                                var data = new Blob([result.data]);
                                var reader = new FileReader();
                                reader.readAsDataURL(data);
                                reader.onloadend = function () {
                                    self.base64data = reader.result;
                                    self.wavesurfer.load(self.base64data);
                                }
                            }
                        })

                        var uniqueSpeakers = [];
                        self.speakers.forEach(function (item) {
                            if (uniqueSpeakers.indexOf(item) < 0) {
                                uniqueSpeakers.push(item);
                            }
                        });

                        // crete regions
                        var Colours = {};
                        //hue random value
                        var colourStartValue = ~~(Math.random() * 360);
                        // ~~ is a double NOT bitwise operator. It is used as a faster substitute for Math.floor().
                        // create a colour for each speaker
                        for (var i = 0; i < uniqueSpeakers.length; i++) {
                            var hueValue = colourStartValue + 30 * i;
                            Colours[i] = randomColor(0.3, hueValue);
                        }

                        self.wavesurfer.on('ready', function () {
                            for (var i = 0; i < transcription.transcript.segments.length; i++) {
                                for (var j = 0; j < uniqueSpeakers.length; j++) {
                                    if (transcription.transcript.segments[i].speaker_name === uniqueSpeakers[j]) {
                                        self.wavesurfer.addRegion({
                                            start: transcription.transcript.segments[i]["segment-start"], // time in seconds
                                            end: transcription.transcript.segments[i]["segment-start"] + transcription.transcript.segments[i]["segment-length"], // time in seconds
                                            drag: false,
                                            resize: false,
                                            color: Colours[j],
                                            attributes: {
                                                label: transcription.transcript.segments[i].speaker_name + ", " + transcription.transcript.segments[i].speaker_gender,
                                                highlight: true
                                            }
                                        });
                                    }
                                }
                            }
                        });

                    }
                })


            } else {
                location.path('/jobs')
            }

        }

        function resetTextHighlight() {
            for (var i = 0; i < Object.keys(self.syncData).length; i++) {
                self.subtitles.children[i].style.background = 'white';
            }
        }

        function createSubtitle()
        {
            var element;
            for (var i = 0; i < Object.keys(self.syncData).length; i++) {
                if (self.syncData[i].hasOwnProperty('start')) {
                    element = document.createElement('span');
                    element.setAttribute("id", "c_" + i);
                    element.setAttribute("start", self.syncData[i].start);
                    //element.setAttribute("duration", self.syncData[i].length);
                    //if syncData[i+1] is a punctuation mark, don't add a white space
                    if (self.syncData[i + 1].word === "," || self.syncData[i + 1].word === ".") {
                        element.innerText = self.syncData[i].word;
                    } else {
                        element.innerText = self.syncData[i].word + " ";
                    }
                    self.subtitles.appendChild(element);
                } else if (self.syncData[i].hasOwnProperty('speaker_name')) {
                    element = document.createElement('span');
                    element.setAttribute("id", "c_" + i);
                    element.innerText = self.syncData[i].speaker_name + ", " + self.syncData[i].speaker_gender + ": ";
                    element.style.fontWeight = 'bold';
                    self.subtitles.appendChild(element);
                } else {
                    var br = document.createElement("br");
                    self.subtitles.appendChild(br);
                }
            }
        }

        function setTextOptions(error) {
            if (error) {
                utils.showError();
            } else {
                Object.keys(self.syncData).forEach(function (element, index) {
                    if (self.syncData[index].hasOwnProperty('start')) {
                        self.subtitles.children[index].addEventListener('click', function () {
                            self.wavesurfer.play(self.syncData[index].start);
                            resetTextHighlight();
                            if (self.wavesurfer.getCurrentTime() >= element.start && self.wavesurfer.getCurrentTime() <= element.start + element.length)
                                self.subtitles.children[index].style.background = 'yellow';
                            self.currentIndex = index;
                        });
                    }
                });
            }
        }

        //// play recording ////
        function playPauseRecording() {
            self.wavesurfer.playPause()
            console.log("Play/Pause")
        }

        function randomColor(alpha, hueValue) {
            // alpha represents the opacity
            if (hueValue > 360) {
                hueValue = hueValue % 360;
            }
            return ('hsl(' + [hueValue] + ',100%,50%,' + [alpha || 1] + ')');
        }

        function downloadJSON() {
            var data = new Blob([self.transcription]);
            FileSaver.saveAs(data, "result.txt");
        }

    }
})();


