(function () {

    angular
        .module('app.core')
        .filter('millSecondsToTimeString', mil)

    function mil() {
        return function (millseconds) {
            var oneSecond = 1000;
            var oneMinute = oneSecond * 60;
            var oneHour = oneMinute * 60;
            var oneDay = oneHour * 24;

            var seconds = Math.floor((millseconds % oneMinute) / oneSecond);
            var minutes = Math.floor((millseconds % oneHour) / oneMinute);
            var hours = Math.floor((millseconds % oneDay) / oneHour);
            var days = Math.floor(millseconds / oneDay);

            var timeString = '';
            if (days !== 0) {
                timeString += (days !== 1) ? (days + ' days ') : (days + ' day ');
            }
            if (hours !== 0) {
                timeString += (hours !== 1) ? (hours + ' hours ') : (hours + ' hour ');
            }
            if (minutes !== 0) {
                timeString += (minutes !== 1) ? (minutes + ' minutes ') : (minutes + ' minute ');
            }
            if (seconds !== 0 || millseconds < 1000) {
                timeString += (seconds !== 1) ? (seconds + ' seconds ') : (seconds + ' second ');
            }

            return timeString;
        };
    }
})();
