(function () {

    angular
            .module('app.user')
            .controller('UserCtrl', user)

    user.$inject = ['utils', 'UserService', 'md5']

    function user(utils, service, md5) {
        var self = this
        self.user = {}
        // make method public
        self.updateDetails = updateDetails
        // init
        init()

        //// init ////
        function init() {
            service.getUserDetails(function (error, user) {
                // hide loader
                utils.hideLoader()
                // handle response
                if (error)
                    utils.showError(error)
                else {
                    self.user = user
                    //for displaying data in hours (not used)
                    //self.user.diarizationSeconds = self.user.diarizationSeconds/3600;
                    //
                    // convert seconds to milieconds
                    self.user.diarizationSeconds = self.user.diarizationSeconds * 1000
                    self.user.speechToTextSeconds = self.user.speechToTextSeconds * 1000
                    self.user.keywordsSeconds = self.user.keywordsSeconds * 1000
                }

            })
        }

        //// update details ////
        function updateDetails() {
            // show loader
            utils.showLoader()
            var _user = angular.copy(self.user)
            _user.password = md5.createHash(self.user.password)
            service.updateUserDetails(_user, function (error) {
                // hide loader
                utils.hideLoader()
                // handle response
                console.log(error)
                if (error)
                    utils.showError(error.data.message)
                else {
                    utils.showSuccess()
                }
            })
        }
    }

})();
