(function() {

    angular
        .module('app.auth')
        .controller('ForgotCtrl', controller)

    function controller() {

    }

})();