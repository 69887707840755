(function () {

    angular
        .module('app.auth', [])
        .config(config)

    function config($stateProvider) {
        // configure states
        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'app/main/auth/login/login.html',
                controller: 'LoginCtrl',
                controllerAs: 'vm',
                resolve: {
                    skipIfLoggedIn: function (AuthService) {
                        return AuthService.skipIfLoggedIn()
                    }
                }
            })
            .state('signup', {
                url: '/signup',
                templateUrl: 'app/main/auth/signup/signup.html',
                controller: 'SignupCtrl',
                controllerAs: 'vm',
                resolve: {
                    skipIfLoggedIn: function (AuthService) {
                        return AuthService.skipIfLoggedIn()
                    }
                }
            })
            .state('forgot', {
                url: '/forgot',
                templateUrl: 'app/main/auth/forgot-password/forgot-password.html',
                controller: 'ForgotCtrl',
                controllerAs: 'vm',
                resolve: {
                    skipIfLoggedIn: function (AuthService) {
                        return AuthService.skipIfLoggedIn()
                    }
                }
            })
            .state('logout', {
                url: '/logout',
                template: null,
                controller: 'LogoutCtrl'
            })
    }

})();