(function(){

    angular
        .module('Speech')
        .run(runBlock)

    runBlock.$inject =['$rootScope', '$location', 'utils']

    function runBlock(rootScope, location, utils){
        // automatically display loader on route changed
        // the loader will dissapear when 'init' method from
        // every controller will finish. Please note
        // that if one of the pages does not load one of the problems
        // might be that you don't have an init, etc.
        rootScope.$on('$stateChangeStart', function () {
            if (location.path() != '/login'
                && location.path() != '/signup'
                && location.path() != '/forgot'
                && location.path() != '/logout') {
                // show loader only if we go to a page where the controller
                // needs data from the db
                // utils.showLoader()
            }
        })
    }

})();