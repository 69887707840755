(function () {

    angular
            .module('app.home')
            .controller('JobsCtrl', home)

    home.$inject = ['utils', 'HomeService', 'FileSaver', 'Blob', '$location']

    function home(utils, service, FileSaver, Blob, location) {
        var self = this
        self.jobs = null
        self.json = null

        self.viewTranscription = viewTranscription
        self.downloadJSON = downloadJSON
        // init
        init()

        //// init ////
        function init() {
            // get list of files
            service.getListOfFiles(function (error, files) {
                if (error) {
                    utils.hideLoader()
                    utils.showError('Could not fetch list of audio files')
                } else {
                    // get jobs
                    service.getListOfJobs(function (error, jobs) {
                        utils.hideLoader()
                        if (error)
                            utils.showError('Could not get jobs')
                        else {

                            // add file status from jobs
                            completeJobsObject(jobs, files)
                        }
                    })
                }
            })
        }

        //// Helper - complete job object ////
        function completeJobsObject(jobs, files) {
            var new_jobs = []
            jobs.forEach(function (job) {
                var fl = files.filter(function (file) {
                    return file.audioId == job.audioId
                })

                if (fl && fl.length > 0) {
                    var dst = {}
                    angular.extend(dst, fl[0], job)
                    job = dst
                }

                job.length = job.length * 1000

                new_jobs.push(job)

            }, this)

            self.jobs = new_jobs
            self.files = files

            //console.log(self.jobs, self.files)
        }

        //// Helper -> view keywords ////
        function viewTranscription(job) {
            var url = '/view-transcription/' + job.jobId + '/' + job.audioId + '/' + job.fileName + '/' + job.asrDomainName
            location.path(url)
        }

        function downloadJSON(job) {
            service.getTranscription(job.jobId, function (error, transcription) {
                if (error) {
                    utils.showError()
                } else {
                    self.json = JSON.stringify(transcription);
                    var data = new Blob([self.json]);
                    FileSaver.saveAs(data, "result.txt");
                }
            })
        }
    }

})();

