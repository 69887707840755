
// navbar controller
(function () {

    angular
        .module('app.core')
        .controller('ProgressIndicatorCtrl', ['$scope', progress])

    function progress($scope) {
        // Keeps track of active tasks.
        var loadingTasks = []

        // Used in the template to show/hide the indicator.
        $scope.isLoading = false

        /**
         * Listener for the 'startedProgress' event.
         * Registers an active task.
         */
        $scope.$on('startedProgress', function (event, task) {
            // Add the task to the list.
            loadingTasks.push(task)

            // Show the indicator.
            $scope.isLoading = true
        })

        /**
         * Listener for the 'finishedProgress' event.
         * Removes an active task.
         */
        $scope.$on('finishedProgress', function (event, task) {
            // Remove the task from the list of active tasks.
            var index = loadingTasks.indexOf(task)
            if (index != -1) {
                loadingTasks.splice(index, 1)
            }

            // If nothing else is in progress, hide the indicator.
            if (loadingTasks.length == 0) {
                $scope.isLoading = false
            }
        })

        /**
         * Sets is loading true no matter what
         */
        $scope.$on('showProgressIndicator', function (event) {
            $scope.isLoading = true
        })

        /**
         * Sets is loading false no matter what
         */
        $scope.$on('hideProgressIndicator', function (event) {
            $scope.isLoading = false
        })

        /**
         * Listener for the 'resetProgressIndicator' event.
         * Hides the indicator and resets the list of active tasks.
         */
        $scope.$on('resetProgressIndicator', function (event, task) {
            loadingTasks = []
            $scope.isLoading = false
        })


    }

})();