(function () {

    angular
        .module('app.user')
        .service('UserService', ['utils', 'urls', '$http', user])


    function user(utils, urls, $http) {

        this.getUserDetails = function (cb) {
            $http.get(urls.getUserInfo)
                .then(function (user) {
                    cb(null, user.data)
                })
                .catch(function (error) {
                    cb(error)
                })
        }

        this.updateUserDetails = function (details, cb) {
            $http.put(urls.updateUserInfo, details)
                .then(function () {
                    cb()
                })
                .catch(function (error) {
                    cb(error)
                })
        }

    }

})();