(function () {

    angular
        .module('app.home')
        .service('HomeService', ['utils', 'urls', '$http', '$auth', 'Upload', home])


    function home(utils, urls, $http, $auth, Upload) {

        //// get list of jobs ////
        this.getListOfJobs = function (cb) {
            $http.get(urls.getAudioJobsURL)
                .then(function (jobs) {
                    cb(null, jobs.data)
                })
                .catch(function (error) {
                    cb(error)
                })
        }

        //// get list of files ///
        this.getListOfFiles = function (cb) {
            $http.get(urls.getAudioListURL)
                .then(function (res) {
                    cb(null, res.data)
                })
                .catch(function (error) {
                    cb(error)
                })
        }

        //// upload text file ////
        this.uploadFile = function (input, cb) {
            Upload.upload({
                url: urls.uploadAudioFileURL,
                method: 'POST',
                headers: {
                    'Authorization': $auth.getToken()
                },
                data: { audioFile: input }
            })
                .then(function (res) {
                    cb()
                })
                .catch(function (err) {
                    cb(err)
                })
        }

        //// get ASR Domains ////
        this.getASRDomains = function (cb) {
            $http.get(urls.getAsrURL)
                .then(function (res) {
                    cb(null, res.data)
                })
                .catch(function (error) {
                    cb(error)
                })
        }


        //// delete file ////
        this.deleteFile = function (id, cb) {
            $http.delete(urls.deleteAudioURL + id)
                .then(function () {
                    cb(null)
                })
                .catch(function (err) {
                    cb(err)
                })
        }

        //// request spotting ////
        this.requestSpotting = function (domainId, audioId, keywords, cb) {
            $http.post(urls.getKwdRequestURL + audioId + '/' + domainId, keywords)
                .then(function () {
                    cb()
                })
                .catch(function (err) {
                    cb(err)
                })

        }
        
        //// request transcription ////
        this.requestTrnscriprion = function (audioId, data, cb) {
            $http.post(urls.postTranscriptionRequestURL + audioId, data)
                .then(function () {
                    cb()
                })
                .catch(function (err) {
                    cb(err)
                })

        }
        
        //// get transcription ////
        this.getTranscription = function (jobId, cb) {
            $http.get(urls.getTranscriptionURL + jobId)
                .then(function (res) {
                    cb(null, res.data)
                })
                .catch(function (error) {
                    cb(error)
                })
        }

        //// get audio file ////
        this.getAudioFile = function (audioId, cb) {
            $http.get(urls.getFileURL + audioId)
                .then(function (data, textStatus, xhr) {
                    if (xhr.status !== 204) {
                        var obj = JSON.parse(data);
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    cb(error)
                })
        }
	
	//// download file ////
        this.downloadFile = function(audioid, cb) {
            $http.get(urls.getFileURL + audioid, {responseType: 'blob'})
                .then(function(res){
                    cb(null, res)
                })
                .catch(function(error){
                    cb(error)
                })
        }

    }

})();

