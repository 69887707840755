(function () {
    angular
        .module('app.core')
        .config(config)

    function config() {

    }


})();