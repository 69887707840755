(function () {

    angular
        .module('app.core')
        .service('utils', utils)

    utils.$inject = ['$rootScope', 'toastr']

    function utils($rootScope, toastr) {
        var self = this

        // methods
        self.showLoader = showLoader
        self.hideLoader = hideLoader
        self.displayAlert = displayAlert
        self.showError = showError
        self.showSuccess = showSuccess

        //// show loader ////
        function showLoader() {
            $rootScope.$broadcast('showProgressIndicator')
        }
        //// hide loader ////
        function hideLoader() {
            $rootScope.$broadcast('hideProgressIndicator')
        }
        //// show alert ////
        function displayAlert(text) {
            // TODO: implement alert
        }

        //// show error ////
        function showError(text) {
            var txt = text ? text : 'Error'
            toastr.error(txt)
        }

        //// show success ////
        function showSuccess(text) {
            var txt = text ? text : 'Success'
            toastr.success(txt)
        }

    }

})();