(function () {

    angular
        .module('app.auth')
        .controller('LogoutCtrl', logout)

    logout.$inject = ['$rootScope', '$auth', '$location', 'utils']
    function logout($rootScope, $auth, $location, utils) {
        if (!$auth.isAuthenticated())
            $location.path('/audio-files')
        $auth.logout()
            .then(function () {
                $rootScope.$emit('loggedout')
                $location.path('/audio-files')
            })
            .catch(function (error) {
                utils.displayAlert(error)
            })
    }

})();