(function () {

    angular
        .module('app.core')
        .service('calls', calls)

    calls.$inject = ['urls', '$http']

    function calls(urls, $http) {



    }

})();