(function () {


    angular
        .module('Speech', [
            'app.core',
            'app.auth',
            'app.home',
            'app.user'


        ])

})();