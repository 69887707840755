(function () {

    angular
        .module('app.auth')
        .service('AuthFactory', ['$http', 'urls', factory])

    function factory($http, urls) {
        this.login = function (json, cb) {
            $http.post(urls.loginURL, json)
                .then(function (response) {
                    cb(null, response.data)
                })
                .catch(function (error) {
                    cb(error)
                })
        }

        this.signup = function (json, cb) {
            console.log(urls.signupURL)
            $http.post(urls.signupURL, json)
                .then(function (response) {
                    cb(null, response.data)
                })
                .catch(function (error) {
                    cb(error)
                })
        }
    }

})();