(function () {
    angular
        .module('app.core', [
            'ngAnimate',
            'ui.router',
            'satellizer',
            'angularAudioRecorder',
            'ui.materialize',
            'ngFileUpload',
            'toastr',
	    'angular-md5',
	    'ngFileSaver'
        ])


})();
