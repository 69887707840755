(function(){

    angular
        .module('app.core')
        .run(runBlock)


    function runBlock(){

    }

})();